@font-face {
  font-family: ar-font;
  src: url(./fonts/JFFlatregular.ttf);
}

body {
  direction: rtl;
  font-family: ar-font;
  overflow-x: hidden;
}

nav.navbar {
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  border-bottom: 1px solid transparent;
  z-index: 20;
  text-align: right;
}

nav.navbar button.navbar-toggler {
  margin: 30px auto;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

nav.navbar button.navbar-toggler span {
  color: #ffffff;
}

nav.navbar .navbar-brand img {
  width: 80%;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

nav.navbar ul.navbar-nav {
  padding-right: 0;
}

nav.navbar ul.navbar-nav li a.nav-link {
  color: #ffffff;
  font-size: 16px;
  line-height: 40px;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

nav.navbar ul.navbar-nav li a.nav-link:hover,
nav.navbar ul.navbar-nav li a.nav-link.active {
  color: #16aeca;
  background-color: #ffffff;
}

nav.navbar.noTransparrent {
  background-color: #ffffff;
  border-bottom: 1px solid #eee;
}

nav.navbar.noTransparrent button.navbar-toggler {
  margin: 30px auto;
}

nav.navbar.noTransparrent button.navbar-toggler span {
  color: #16aeca;
}

nav.navbar.noTransparrent .navbar-brand img {
  width: 80%;
}

nav.navbar.noTransparrent ul.navbar-nav li a.nav-link {
  color: #16aeca;
}

nav.navbar.noTransparrent ul.navbar-nav li a.nav-link:hover,
nav.navbar.noTransparrent ul.navbar-nav li a.nav-link.active {
  color: #dae413;
  background-color: #16aeca;
}

/* ==============
    header
============== */

header {
  background-image: url(./images/bg.jpg);
  background-position: center center;
  background-size: cover;
  min-height: 100vh;
}

header .overlay {
  background: rgba(41, 153, 155, 0.8);
  background: linear-gradient(
    to right,
    rgba(41, 153, 155, 0.8) 0%,
    rgba(167, 173, 44, 0.8) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(41, 153, 155, 0.8)),
    color-stop(100%, rgba(167, 173, 44, 0.8))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(41, 153, 155, 0.8) 0%,
    rgba(167, 173, 44, 0.8) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#29999b', endColorstr='#a7ad2c', GradientType=1);
}

header .overlay .slider .carousel-inner .carousel-item {
  min-height: 100vh;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

header .overlay .slider .carousel-inner .carousel-item .carousel-caption {
  bottom: 30%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

header .overlay .slider .carousel-inner .carousel-item .carousel-caption h3 {
  font-size: 40px;
  line-height: 60px;
  transform: translateY(-50px);
  transition: all 1s ease;
}

header .overlay .slider .carousel-inner .carousel-item .carousel-caption a {
  font-size: 18px;
  line-height: 50px;
  background-color: #16aeca;
  color: #ffffff;
  border-radius: 30px;
  padding: 0px 2rem;
  margin-top: 30px;
  display: inline-block;
  transform: translateY(50px);
  transition: all 1s ease;
}

header
  .overlay
  .slider
  .carousel-inner
  .carousel-item
  .carousel-caption
  a:hover {
  text-decoration: none;
  background-color: #dae413;
  color: #555555;
}

header .overlay .slider .carousel-inner .carousel-item.active {
  opacity: 1;
}

header
  .overlay
  .slider
  .carousel-inner
  .carousel-item.active
  .carousel-caption {
  opacity: 1;
}

header
  .overlay
  .slider
  .carousel-inner
  .carousel-item.active
  .carousel-caption
  h3,
header
  .overlay
  .slider
  .carousel-inner
  .carousel-item.active
  .carousel-caption
  a {
  transform: translateY(0px);
}

header .overlay .slider .carousel-control-prev,
header .overlay .slider .carousel-control-next {
  top: auto;
  bottom: 45%;
  width: auto;
}

header .overlay .slider .carousel-control-prev i,
header .overlay .slider .carousel-control-next i {
  padding: 30px 20px;
  border: 2px solid #ffffff;
  border-radius: 2px;
}

header .overlay .slider .carousel-control-prev {
  left: 20px;
}

header .overlay .slider .carousel-control-next {
  right: 20px;
}

section.services {
  padding: 80px 0;
}

section.services .title h2 {
  font-size: 40px;
  line-height: 60px;
}

section.services .title {
  margin-bottom: 50px;
}

section.services .card {
  background-color: #ffffff;
  width: 100%;
  height: 250px;
  margin-bottom: 20px;
  transition: all 1s ease;
}

section.services .card:hover {
  height: 350px;
}

section.services .card img {
  width: 140px;
  margin: 40px auto 0;
}

section.services .card .card-block h4 {
  font-size: 24px;
  color: #070c0c;
  line-height: 35px;
}

section.services .card .card-block p {
  font-size: 15px;
  line-height: 20px;
  color: #919191;
  opacity: 0;
  transition: all 1s ease;
}

section.services .card:hover .card-block p {
  opacity: 1;
}

section.gallery .title h2 {
  font-size: 40px;
  line-height: 60px;
}

section.gallery .title {
  margin-bottom: 50px;
}

section.gallery .work-gallery a {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 100%;
}
section.gallery .work-gallery a img {
  width: 100%;
}

section.gallery .work-gallery a .hover {
  background-color: rgba(22, 174, 202, 0.9);
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  color: #fff;
  opacity: 0;
  z-index: 9;
  transition: all 0.5s ease;
}

section.gallery .work-gallery a:hover .hover {
  opacity: 1;
}

section.gallery .work-gallery a .hover i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2em;
}

section.gallery .work-gallery .overlay-gallery {
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  right: 0px;
  top: 0px;
  width: 102%;
  height: 100vh;
  z-index: 30;
  overflow: hidden;
  padding: 5% 10%;
  opacity: 0;
  transition: all 1s ease;
  margin-top: -100vh;
  text-align: right;
}

section.gallery .work-gallery .overlay-gallery:target {
  opacity: 1;
  margin-top: 0;
}

section.gallery .work-gallery .overlay-gallery img {
  width: 100%;
}

section.gallery .work-gallery .overlay-gallery .gallery-text h3 {
  font-size: 36px;
  line-height: 52px;
  color: #16aeca;
  margin-bottom: 30px;
}

section.gallery .work-gallery .overlay-gallery .gallery-text ul {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #efefef;
  padding-right: 0;
}

section.gallery .work-gallery .overlay-gallery .gallery-text ul li a {
  color: #555555;
}

section.gallery .work-gallery .overlay-gallery .gallery-text p {
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 50px;
  color: #919191;
}

section.gallery .work-gallery .overlay-gallery .gallery-text a.visit {
  font-size: 20px;
  line-height: 50px;
  border-radius: 25px;
  padding: 0px 30px;
  background-color: #16aeca;
  color: #ffffff;
  width: auto;
}

section.gallery .work-gallery .overlay-gallery .gallery-text {
  margin-top: 40px;
}

section.gallery .work-gallery .overlay-gallery a.close {
  color: #16aeca;
  position: absolute;
  top: 4%;
  left: 10%;
  font-size: 30px;
  font-weight: bold;
  width: auto;
}

section.statistics {
  background-image: url(./images/bg2.jpg);
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
}

section.statistics .overlay {
  background-color: rgba(22, 174, 202, 0.8);
  padding: 100px 0;
}

section.statistics .overlay .stat p {
  font-size: 18px;
  color: #85ecff;
  line-height: 22px;
}

section.statistics .overlay .stat .count {
  font-size: 40px;
  line-height: 60px;
  font-weight: bold;
  color: #ddfaff;
}

section.contacts {
  background-color: #ffffff;
  padding-top: 100px;
  text-align: right;
}

section.contacts .info h3 {
  font-size: 24px;
  line-height: 50px;
  color: #181c1c;
  font-weight: bold;
}

section.contacts .info ul li {
  font-size: 18px;
  line-height: 25px;
  color: #16aeca;
  margin-bottom: 15px;
}

section.contacts .info ul {
  padding: 0;
}

section.contacts .info ul li i {
  margin-left: 6px;
}

section.contacts .form h3 {
  font-size: 24px;
  line-height: 50px;
  color: #181c1c;
  font-weight: bold;
  margin: 50px auto 20px;
}

section.contacts .form form input,
section.contacts .form form textarea {
  background-color: #f7f7f7;
  border: 2px solid #eee;
  border-radius: 3px;
  color: #5c5c5c;
  font-family: ar-font;
  font-size: 15px;
  line-height: 25px;
}

section.contacts .form form button {
  background-color: #16aeca;
  font-family: ar-font;
  font-size: 15px;
  color: #ffffff;
  line-height: 25px;
  padding: 10px 25px;
  border-radius: 3px;
  border: 0;
  margin-right: 15px;
}

section.contacts .cmxform {
  width: 60%;
  margin: 0 auto 50px;
}

section.contacts .form form .error {
  color: red;
}

footer .top-footer {
  background-color: #171717;
  padding: 75px 0;
  text-align: right;
}

footer .top-footer h3 {
  font-size: 18px;
  line-height: 45px;
  color: #85ecff;
  margin-bottom: 30px;
  position: relative;
}

footer .top-footer h3:before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 35px;
  height: 2px;
  background-color: #5c5c5c;
}

footer .top-footer p {
  font-size: 15px;
  line-height: 22px;
  color: #919191;
  margin-bottom: 50px;
}

footer .top-footer ul {
  padding: 0;
}

footer .top-footer ul.social li a i {
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  border-radius: 2px;
  background-color: #333333;
  color: #acacac;
  transition: all 0.5s ease;
}

footer .top-footer ul.social li {
  margin-right: 5px;
}

footer .top-footer ul.social li a:hover i {
  background-color: #16aeca;
}

footer .top-footer ul.contact-us li {
  margin-bottom: 35px;
  font-size: 18px;
  color: #676767;
}

footer .top-footer ul.contact-us li:last-of-type {
  margin-bottom: 0px;
}

footer .top-footer ul.contact-us li i {
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  border-radius: 2px;
  background-color: #282828;
  color: #acacac;
  margin-left: 20px;
}

footer .copyright {
  background-color: #111111;
}

footer .copyright p {
  font-size: 15px;
  line-height: 78px;
  color: #919191;
  margin-bottom: 0;
}

@media (max-width: 980px) {
  nav.navbar ul.navbar-nav li a.nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  header .overlay .slider .carousel-inner .carousel-item .carousel-caption h3 {
    font-size: 30px;
  }

  header .overlay .slider .carousel-inner .carousel-item .carousel-caption {
    bottom: 25%;
  }

  section.services .card {
    height: 290px;
  }
}

@media (max-width: 767px) {
  .navbar {
    padding: 0.5rem 0rem;
  }
  nav.navbar .container {
    width: 100%;
    max-width: 100%;
    flex-direction: row-reverse;
  }
  .navbar .navbar-toggler {
    outline: none;
    margin: 0 !important;
  }
  .navbar .navbar-toggler span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  nav.navbar .navbar-brand img {
    width: 80%;
  }

  nav.navbar .navbar-collapse {
    background-color: #16aeca;
  }
  nav.navbar.noTransparrent .navbar-collapse {
    background-color: #ffffff;
  }

  section.gallery .work-gallery .overlay-gallery .gallery-text h3 {
    font-size: 18px;
    line-height: 36px;
    margin-bottom: 10px;
  }

  section.gallery .work-gallery .overlay-gallery .gallery-text p {
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 30px;
  }

  section.gallery .work-gallery .overlay-gallery .gallery-text a.visit {
    font-size: 14px;
    line-height: 40px;
  }

  section.contacts .cmxform {
    width: 100%;
  }
}

@media (max-width: 575px) {
  header .overlay .slider .carousel-inner .carousel-item .carousel-caption {
    left: 2%;
    right: 2%;
  }

  header .overlay .slider .carousel-control-prev,
  header .overlay .slider .carousel-control-next {
    display: none;
  }

  .work-gallery .col-md > .col-sm > a {
    display: block !important;
  }

  .work-gallery .col-md > .col-sm > a img {
    width: 100%;
  }

  section.gallery .work-gallery .overlay-gallery img {
    width: 50%;
  }
}
