@font-face {
  src: url(./fonts/Cairo-Regular.ttf);
  font-family: ar-font;
}

body {
  font-family: ar-font;
  direction: rtl;
}

header {
  background-image: url(./images/bg.jpg);
  background-size: cover;
  background-position: center center;
  min-height: 80vh;
}

header .MainCard {
  background-color: rgba(5, 117, 230, 0.8);
  color: #ffffff;
  transform: translateY(20%);
  padding: 3% 10%;
}

header .MainCard img {
  border: 5px solid #72ace7;
  margin-bottom: 20px;
}

header .MainCard h1 {
  font-size: 36px;
  color: #f9f9f9;
  line-height: 50px;
  margin-bottom: 20px;
}

header .MainCard h1 span {
  font-size: 24px;
}

header .MainCard ul {
  padding: 0;
  margin-bottom: 40px;
}

header .MainCard ul li {
  margin-right: 10px;
}

header .MainCard ul li a {
  color: #021b79;
  font-size: 24px;
}

header .MainCard h2 {
  font-size: 15px;
  line-height: 30px;
  font-weight: bold;
  margin-bottom: 15px;
}

header .MainCard p {
  font-size: 15px;
  line-height: 30px;
  color: #89c4ff;
  margin-bottom: 40px;
}

header .MainCard > a {
  color: #ffffff;
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 40px;
}
nav.navbar {
  text-align: right;
}

nav.navbar ul.navbar-nav li a.nav-link {
  color: #ffffff;
  font-size: 16px;
  line-height: 60px;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  transition: all 0.5s ease;
}

nav.navbar ul.navbar-nav li a.nav-link:hover,
nav.navbar ul.navbar-nav li a.nav-link.active {
  color: #0575e6;
  background-color: #ffffff;
}

nav.navbar.noTransparrent ul.navbar-nav li a.nav-link {
  color: #0575e6;
}

nav.navbar.noTransparrent ul.navbar-nav li a.nav-link:hover,
nav.navbar.noTransparrent ul.navbar-nav li a.nav-link.active {
  color: #ffffff;
  background-color: #0575e6;
}

nav.navbar.noTransparrent {
  background-color: #ffffff;
  border-bottom: 1px solid #eee;
}

nav.navbar.noTransparrent button.navbar-toggler {
  margin: 30px auto;
}

nav.navbar.noTransparrent button.navbar-toggler span {
  color: #0575e6;
}

@media (max-width: 767px) {
  nav.navbar {
    min-height: 80px;
  }

  nav.navbar .container {
    width: 100%;
    position: relative;
    min-height: 40px;
  }
  nav.navbar button.navbar-toggler {
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
  }
  nav.navbar button.navbar-toggler span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  nav.navbar.noTransparrent button.navbar-toggler {
    margin: 0;
  }
  nav.navbar ul {
    padding-right: 0;
  }
}

section.licence {
  padding: 220px 0 80px;
  background-color: #f6fbff;
  text-align: right;
}

section.licence .title {
  padding-bottom: 43px;
  position: relative;
}

section.licence .title h2 {
  font-size: 30px;
  line-height: 50px;
  color: #196dd1;
}

section.licence .title:before {
  content: "";
  position: absolute;
  width: 100px;
  height: 2px;
  background-color: #196dd1;
  bottom: 0;
  right: 50%;
  transform: translateX(50%);
}

section.licence .title h2 i {
  display: block;
}

section.licence ul.timeline li .time-box {
  background-color: #ffffff;
  border: 2px solid #f4f3f3;
  padding: 20px 25px;
  max-width: 48%;
  position: relative;
}

section.licence ul.timeline li .time-box h4 {
  font-size: 18px;
  line-height: 30px;
  color: #555555;
}

section.licence ul.timeline li .time-box h4 span {
  color: #ffffff;
  background: #0575e6;
  padding: 0 5px;
  margin-left: 25px;
}

section.licence ul.timeline li .time-box p {
  font-size: 15px;
  line-height: 22px;
  color: #b1b1b1;
}

section.licence ul.timeline li .time-box:after {
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  content: "";
  position: absolute;
  top: 5%;
  left: -10px;
  border-left: 2px solid #f4f3f3;
  border-bottom: 2px solid #f4f3f3;
  transform: rotate(45deg);
}

section.licence ul.timeline {
  padding-right: 0;
  padding-top: 45px;
  position: relative;
}

section.licence ul.timeline:before {
  content: "";
  width: 2px;
  height: 75%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 50%;
  margin-right: -1px;
  background: #196dd1;
}

section.licence ul.timeline li {
  position: relative;
}

section.licence ul.timeline li:before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #196dd1;
  position: absolute;
  top: 9%;
  right: 50%;
  margin-right: -5px;
}

section.licence ul.timeline li:nth-of-type(even) {
  direction: ltr;
  text-align: left;
}

section.licence ul.timeline li:nth-of-type(even) .time-box h4 span {
  margin-left: 0;
  margin-right: 25px;
  float: left;
}

section.licence ul.timeline li:nth-of-type(even) .time-box:after {
  left: auto;
  right: -10px;
  border: 0;
  border-top: 2px solid #f4f3f3;
  border-right: 2px solid #f4f3f3;
}

section.licence ul.timeline li:not(:first-of-type) {
  margin-top: -50px;
}

section.statistics {
  background-image: url(./images/bg2.jpg);
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}

section.statistics .overlay {
  background-color: rgba(5, 117, 230, 0.7);
  padding: 150px 0;
}

section.statistics .overlay .stat p {
  font-size: 24px;
  line-height: 30px;
  font-family: "Open Sans";
  color: #ffffff;
  margin-top: 30px;
}

section.statistics .overlay .stat .circle canvas {
  background-color: #eef6ff;
  border-radius: 50%;
  padding: 8px;
}

section.statistics .overlay .stat .circle {
  position: relative;
}

section.statistics .overlay .stat .circle span {
  font-size: 24px;
  font-weight: bold;
  color: #0575e6;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

section.gallery {
  padding: 75px 0 90px;
}

section.gallery .title {
  margin-bottom: 35px;
}

section.gallery .title h2 {
  font-size: 30px;
  line-height: 50px;
  color: #0575e6;
}

section.gallery .title h2 i {
  display: block;
}

section.gallery .work-gallery input {
  display: none;
}

section.gallery .work-gallery label {
  font-size: 18px;
  line-height: 35px;
  color: #8f9899;
  cursor: pointer;
  margin: 0 7px 40px;
  padding: 10px 30px;
  background-color: #ffffff;
  border: 2px solid #e4eeee;
  border-radius: 2px;
  transition: all 0.5s ease;
}

section.gallery .work-gallery label:hover {
  color: #ffffff;
  background-color: #0575e6;
  border: 2px solid #0575e6;
}

section.gallery .work-gallery input.item-type-1:checked ~ label.label-type-1,
section.gallery .work-gallery input.item-type-2:checked ~ label.label-type-2,
section.gallery .work-gallery input.item-type-3:checked ~ label.label-type-3,
section.gallery
  .work-gallery
  input.item-type-all:checked
  ~ label.label-type-all {
  color: #ffffff;
  background-color: #0575e6;
  border: 2px solid #0575e6;
}

section.gallery .work-gallery input.item-type-1:checked ~ .items .item-type-1,
section.gallery .work-gallery input.item-type-2:checked ~ .items .item-type-2,
section.gallery .work-gallery input.item-type-3:checked ~ .items .item-type-3 {
  transform: scale(1.08);
  opacity: 1;
}

section.gallery
  .work-gallery
  input.item-type-1:checked
  ~ .items
  .item:not(.item-type-1),
section.gallery
  .work-gallery
  input.item-type-2:checked
  ~ .items
  > .item:not(.item-type-2),
section.gallery
  .work-gallery
  input.item-type-3:checked
  ~ .items
  > .item:not(.item-type-3) {
  transform: scale(0.8);
  opacity: 0.5;
}

section.gallery .work-gallery .items .item {
  transition: all 0.6s ease;
  margin-bottom: 25px;
}

section.contacts {
  background-image: url(./images/bg3.jpg);
  background-position: center center;
  background-size: cover;
  text-align: right;
}

section.contacts .overlay {
  background-color: rgba(0, 0, 0, 0.6);
  padding-top: 100px;
}

section.contacts .overlay .testimonials .title h3 {
  font-size: 30px;
  color: #ffffff;
  margin-bottom: 27px;
  line-height: 50px;
}

section.contacts .overlay .testimonials .slider {
  width: 65%;
  margin: 0px auto 80px;
}

section.contacts .overlay .testimonials .slider .carousel {
  min-height: 250px;
  color: #b1b1b1;
}

section.contacts
  .overlay
  .testimonials
  .slider
  .carousel
  .carousel-item
  .carousel-caption {
  background-color: #ffffff;
  width: 100%;
  padding: 80px 70px 35px;
  right: 0;
  left: 0;
}

section.contacts .overlay .testimonials .slider .carousel .carousel-item {
  min-height: 350px;
}

section.contacts
  .overlay
  .testimonials
  .slider
  .carousel
  .carousel-item
  .carousel-caption
  img {
  position: absolute;
  top: 0;
  right: 50%;
  transform: translate(50%, -50%);
}

section.contacts
  .overlay
  .testimonials
  .slider
  .carousel
  .carousel-item
  .carousel-caption
  h3 {
  font-size: 18px;
  color: #555555;
  margin-bottom: 15px;
}

section.contacts
  .overlay
  .testimonials
  .slider
  .carousel
  .carousel-item
  .carousel-caption
  h3
  span {
  font-size: 15px;
  color: #9c9c9c;
}

section.contacts
  .overlay
  .testimonials
  .slider
  .carousel
  .carousel-item
  .carousel-caption
  p {
  font-size: 15px;
  line-height: 22px;
  color: #b1b1b1;
  margin-bottom: 25px;
}

section.contacts
  .overlay
  .testimonials
  .slider
  .carousel
  .carousel-item
  .carousel-caption
  ul
  li
  a {
  font-size: 24px;
  color: #b2d8fe;
}

section.contacts
  .overlay
  .testimonials
  .slider
  .carousel
  .carousel-item
  .carousel-caption
  ul {
  padding-right: 0;
}

section.contacts
  .overlay
  .testimonials
  .slider
  .carousel
  .carousel-item
  .carousel-caption
  ul
  li {
  margin-right: 5px;
}

section.contacts
  .overlay
  .testimonials
  .slider
  .carousel
  .carousel-indicators
  li {
  max-width: 15px;
  height: 15px;
  border: 1px solid #0575e6;
  background-color: #ffffff;
}

section.contacts
  .overlay
  .testimonials
  .slider
  .carousel
  .carousel-indicators
  li.active {
  background-color: #0575e6;
}

section.contacts .overlay .testimonials .slider .carousel .carousel-indicators {
  padding-right: 0;
  margin: 0;
  bottom: 12px;
}

section.contacts
  .overlay
  .testimonials
  .slider
  .carousel
  .carousel-control-next,
section.contacts
  .overlay
  .testimonials
  .slider
  .carousel
  .carousel-control-prev {
  top: 50%;
  bottom: auto;
  width: auto;
  transform: translate(50%, -50%);
  z-index: 10;
}

section.contacts
  .overlay
  .testimonials
  .slider
  .carousel
  .carousel-control-next
  i,
section.contacts
  .overlay
  .testimonials
  .slider
  .carousel
  .carousel-control-prev
  i {
  padding: 10px 20px;
  background: #0575e6;
  border-radius: 50%;
}

section.contacts
  .overlay
  .testimonials
  .slider
  .carousel
  .carousel-control-prev {
  transform: translate(-50%, -50%);
}

section.contacts .overlay .contact-info {
  background-color: #ffffff;
  padding: 50px;
}

section.contacts .overlay .contact-info .title h3 {
  font-size: 30px;
  color: #0575e6;
  margin-bottom: 35px;
  line-height: 50px;
}

section.contacts .overlay .contact-info .form form .form-group input,
section.contacts .overlay .contact-info .form form .form-group textarea {
  border: 2px solid #f1f1f1;
  border-radius: 3px;
  font-family: ar-font;
  font-size: 18px;
  color: #c2c2c2;
  line-height: 35px;
  padding-right: 45px;
}

section.contacts .overlay .contact-info .form form .form-group textarea {
  min-height: 220px;
  max-height: 220px;
}

section.contacts .overlay .contact-info .form form .form-group {
  position: relative;
}

section.contacts .overlay .contact-info .form form .form-group:before {
  font-family: FontAwesome;
  position: absolute;
  top: 8px;
  right: 30px;
  font-size: 18px;
  line-height: 25px;
  color: #0575e6;
  display: inline-block;
}

section.contacts
  .overlay
  .contact-info
  .form
  form
  .form-group:first-of-type:before {
  content: "\f007";
}

section.contacts
  .overlay
  .contact-info
  .form
  form
  .form-group:nth-of-type(2):before {
  content: "\f0e0";
}

section.contacts .overlay .contact-info .form form button {
  font-family: ar-font;
  font-size: 24px;
  color: #0575e6;
  line-height: 25px;
  background-color: #ffffff;
  padding: 10px 70px;
  border: 2px solid #0575e6;
  border-radius: 3px;
  margin-right: 15px;
}

section.contacts .overlay .contact-info ul.contact-us li {
  font-family: "Open Sans";
  font-size: 16px;
  line-height: 30px;
  color: #0575e6;
}

section.contacts .overlay .contact-info ul {
  padding: 0;
}

section.contacts .overlay .contact-info ul.social li {
  margin-right: 9px;
}

section.contacts .overlay .contact-info ul.social li a i {
  background-color: #9bc8f5;
  color: #ffffff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  transition: all 0.3s ease;
}

section.contacts .overlay .contact-info ul.social li a i:hover {
  background-color: #0575e6;
}

section.contacts .overlay .copyright p {
  font-size: 18px;
  color: #ececec;
  line-height: 77px;
  margin: 0px;
}

section.contacts form .error {
  color: red;
}

@media (max-width: 990px) {
  nav.navbar ul.navbar-nav li a.nav-link {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }

  section.contacts .overlay .testimonials .slider .carousel .carousel-item {
    min-height: 390px;
  }

  section.contacts .overlay .contact-info ul.social li a i {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }

  section.contacts .overlay .contact-info .form form .form-group input,
  section.contacts .overlay .contact-info .form form .form-group textarea {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  section.licence ul.timeline li .time-box {
    max-width: 100%;
  }

  section.licence ul.timeline li:not(:first-of-type) {
    margin-top: 20px;
  }

  section.licence ul.timeline li .time-box:after {
    display: none;
  }

  section.licence ul.timeline:before {
    height: 90%;
  }

  section.contacts .overlay .testimonials .slider {
    width: 95%;
  }

  section.contacts
    .overlay
    .testimonials
    .slider
    .carousel
    .carousel-item
    .carousel-caption {
    padding: 60px 30px 20px;
  }
}

@media (max-width: 990px) and (min-width: 768px) {
  section.contacts .overlay .contact-info ul.contact-us li {
    overflow-wrap: break-word;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
}
